const baseUrl = "https://auth-api-beg6ftbghybrhabq.eastus-01.azurewebsites.net";
const authProdUrl = "https://authapi.forsico.io";
const boardApiBaseUrl =
  "https://forsico-board-api-dzadc8hbeuegg9hv.eastus-01.azurewebsites.net";
const supportApiUrl = "https://support.forsico.io";
const supportApiKey = "bebabd708e56b530141a6fdae5aa35b4";
const openAiToken =
  "sk-svcacct-uF5vqdV1gZS_hXhm5gquedvqWas_WqXTUT5dIUi5Ko_oUHL6TJb39fP53vB0HcOVx_lQH_xOgT3BlbkFJC9nx_-65FCUCmyJ6VLBTmm1KAa0ogcOOqBH7Gh2kZPPhlOQUktzIRL6U8QjHOxwlMH-kFowMAA";

module.exports = {
  baseUrl,
  authProdUrl,
  supportApiUrl,
  supportApiKey,
  boardApiBaseUrl,
  openAiToken,
};
